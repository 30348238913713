<template>
  <div class="row">
    <div class="col-md-12">
      <div id="filter-card" class="row py-3 px-4">
        <div class="col-lg-12 col-xl-12 px-0">
          <form @submit.prevent="filterResult()">
            <div class="row align-items-end py-0 my-0 mx-0" v-if="filters">
              <div class="col-lg-3 col-md-3 my-lg-2 my-sm-0 row mx-0 px-0">
                <div class="col-lg-6 col-md-6 col-sm-12 my-2 my-md-0">
                  <date-filter
                    :helper-text="$t('general.start_date')"
                    :model.sync="filters.start_date"
                    name="start_time"
                    title="general.start_date"
                  >
                  </date-filter>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-12 my-2 my-md-0">
                  <date-filter
                    :helper-text="$t('general.end_date')"
                    :model.sync="filters.end_date"
                    name="end_date"
                    title="general.end_date"
                  >
                  </date-filter>
                </div>
              </div>
              <div class="col-xl-2 col-lg-2 col-md-2 col-sm-12">
                <custom-multi-select
                  :max="1"
                  :model.sync="filters.instruction_type"
                  :not-list="true"
                  :required="false"
                  :showInvalid="false"
                  :is-inline="false"
                  :hasBottomMargin="false"
                  :options="convertArrayToObjectByKey(instructionType, 'id', 'name')"
                  :title="$t('report.instruction_type ')"
                  input-width="150px"
                  @onSelect="saveInstructionType"
                />
              </div>
              <div class="col-xl-2 col-lg-2 col-md-2 col-sm-12">
                <custom-multi-select
                  :max="1"
                  :model.sync="filters.supplier_company_id"
                  :not-list="true"
                  :options="supplierCompanyOptions"
                  :is-inline="false"
                  :required="false"
                  :showInvalid="false"
                  :hasBottomMargin="false"
                  :title="$t('waybill.current_account')"
                  input-width="150px"
                />
              </div>
              <div class="col-xl-2 col-lg-2 col-md-2 col-sm-12">
                <custom-multi-select
                  :max="1"
                  :not-list="true"
                  :model.sync="filters.fabric_type"
                  :options="fabricOptions"
                  :title="$t('fabric.fabrics')"
                  :required="false"
                  :is-inline="false"
                  :showInvalid="false"
                  :hasBottomMargin="false"
                  input-width="150px"
                />
              </div>
              <div
                class="col-xl-2 col-lg-2 col-md-2 col-sm-12"
                v-if="filters.instruction_type == 'order'"
              >
                <custom-multi-select
                  :max="1"
                  :not-list="true"
                  :model.sync="filters.expense_id"
                  :options="expenseOptions"
                  :title="$t('expense.expenses')"
                  :required="false"
                  :is-inline="false"
                  :showInvalid="false"
                  :hasBottomMargin="false"
                  input-width="150px"
                />
              </div>
              <div
                class="col-xl-1 col-lg-1 col-md-1 col-sm-12 mt-3 d-flex align-items-center justify-content-end row"
                style="margin: 0 !important"
              >
                <div class="col-12 pr-0">
                  <button
                    class="w-100 btn btn-sm btn-outline-primary rounded-full font-weight-bold py-1"
                    @click.prevent="filterResult()"
                  >
                    {{ $t("general.filter") }}
                  </button>
                </div>
                <div class="col-12 pr-0">
                  <button
                    class="w-100 btn btn-sm btn-outline-primary rounded-full font-weight-bold mt-2 py-1"
                    @click="resetFilters"
                  >
                    {{ $t("general.clear") }}
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div class="col-md-12">
      <dashboard-box :title="$t('report.endorsemet_report')">
        <template v-slot:preview>
          <purchasing-instruction-report-chart
            :start-date.sync="filters.start_date"
            :end-date.sync="filters.end_date"
            :order-id="filters.order_id"
            :supplier-company-id="filters.supplier_company_id"
            :instruction-type="filters.instruction_type"
            :fabric-type="filters.fabric_type"
            :fabric-models="fabricModels"
            :onClickButton.sync="onClickButton"
            :expense-id="filters.expense_id"
          ></purchasing-instruction-report-chart>
        </template>
      </dashboard-box>
      <dashboard-box :title="$t('esc.esc_list')">
        <template v-slot:preview>
          <div @contextmenu.prevent="onContextMenu">
            <data-table
              :bordered="false"
              :borderless="true"
              :fields.sync="fields"
              :items="formattedReport"
              :no-border-collapse="true"
              :outlined="false"
              :page.sync="page"
              :perPage.sync="perPage"
              :striped="false"
              :infiniteId="infiniteId"
              @hitBottom="onHitBottom"
              :sortBy.sync="order"
              :sortDesc.sync="sort"
            ></data-table>
          </div>
        </template>
      </dashboard-box>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { mapGetters, mapMutations } from "vuex";
import moment from "moment";

// COMPONENTS
import DashboardBox from "@/assets/components/DashboardBox";
import DataTable from "@/assets/components/dataTable/DataTable";
import TopWaybillNav from "@/view/pages/waybill/layouts/index/TopWaybillNav";
import SecondWaybillNav from "@/view/pages/waybill/layouts/index/SecondWaybillNav";
import InputFilter from "@/assets/components/filters/InputFilter";
import SelectFilter from "@/assets/components/filters/SelectFilter";
import DateFilter from "@/assets/components/filters/DateFilter";
import CustomMultiSelect from "@/assets/components/inputs/CustomMultiSelect";
import PurchasingInstructionReportChart from "./PurchasingReportChart/PurchasingInstructionReportChart";

import {
  HANDLE_INFINITE_SCROLL,
  LOADING,
  SET_ITEMS,
  SET_LOADING,
  ITEMS,
  SET_FILTER,
  FILTER,
} from "@/core/services/store/report/purchasingInstructionReport.module";

import { GET_ITEMS, GET_ITEMS as REST_GET_ITEM } from "@/core/services/store/REST.module";
import { LARAVEL_DATE_FORMAT, MOMENT_SYSTEM_DATE_FORMAT } from "@/core/config/constant";
import * as _ from "lodash";
import ContextMenu from "@/assets/components/contextMenu/ContextMenu";
import {
  PURCHASING_REPORT_START_DATE_STORAGE_NAME,
  PURCHASING_REPORT_END_DATE_STORAGE_NAME,
} from "@/core/storage/storage-names";

export default {
  name: "PurchasingReport",
  components: {
    SelectFilter,
    InputFilter,
    DataTable,
    DashboardBox,
    TopWaybillNav,
    SecondWaybillNav,
    DateFilter,
    CustomMultiSelect,
    ContextMenu,
    PurchasingInstructionReportChart,
  },
  computed: {
    ...mapGetters({
      loading: LOADING,
      filter: FILTER,
      items: ITEMS,
    }),
    filters: {
      get: function () {
        return this.filter;
      },
      set: function (value) {
        this.setFilter(value);
      },
    },
    expenseOptions() {
      let data = this.expenses;
      return this.convertArrayToObjectByKey(data, "id", "translations.0.name");
    },
    formattedReport() {
      if (!this.items || this.items.data === null || this.items.data === undefined)
        return [];
      let temp = [];
      this.items.data.forEach((item) => {
        temp.push({
          supplier_company: item.supplier_company_name ? item.supplier_company_name : "",
          amount: item.amount.toLocaleString('tr-TR') + " " + item.amount_unit,
          order_number: item.order_number ? item.order_number : "",
        });
      });
      return temp;
    },
  },
  data() {
    return {
      // Filters & pagination
      infiniteId: 1,
      orderOptions: [],
      sort: "desc",
      order: "id",
      sampleStatuses: [],
      fieldsToViewEdit: [],
      customerOptions: [],
      supplierCompanyOptions: [],
      search: null,
      onClickButton: false,
      paginate: [],
      page: 1,
      perPage: 25,
      instructionTypeId: null,
      expenses: [],
      instructionType: [
        { id: "sample", name: this.$t("general.sample") },
        { id: "order", name: this.$t("general.order") },
      ],
      currentStoreType: 99999999,
      startDate: moment().subtract(30, "d"),
      endDate: moment().add(1, "d"),
      download: false,
      reportListUrl: "api/report/purchasing-instruction",
      companies: [],
      fabricOptions: [],
      fabricModels: [],
      fields: [
        {
          key: "supplier_company",
          label: this.$t("general.supplier_company"),
          class: "text-center border border-top-0 border-right-0 font-size-lg",
        },
        {
          key: "order_number",
          label: this.$t("report.order_number"),
          sortable: false,
          class:
            "text-center border border-top-0 border-right-0 font-weight-bolder font-size-lg ",
        },
        {
          key: "amount",
          label: this.$t("general.amount"),
          sortable: false,
          class: "text-center border border-top-0 border-right-0 font-size-lg",
          sortable: true,
        },
      ],
    };
  },
  methods: {
    ...mapMutations({
      setFilter: SET_FILTER,
    }),
    onContextMenu(event) {
      event.preventDefault();
    },
    saveInstructionType(value) {
      let tmpItems = [];
      if (value == "sample") {
        tmpItems = _.filter(this.fabricModels, function (o) {
          return o.type == "sample";
        });
        this.filters.expense_id = null;
      } else if (value == "order") {
        tmpItems = _.filter(this.fabricModels, function (o) {
          return o.type == "order";
        });
      } else {
        tmpItems = _.cloneDeep(this.fabricModels);
      }
      this.fabricOptions = this.convertArrayToObjectByKey(tmpItems, "id", "name");
    },
    filterResult() {
      if (!this.filters.instruction_type || this.filters.instruction_type == null) {
        this.sweetAlertError(this.$t("report.validations.instruction_type"));
        return false;
      }
      this.onClickButton = true;
      localStorage.setItem(
        PURCHASING_REPORT_START_DATE_STORAGE_NAME,
        moment(this.filters.start_date)
      );
      localStorage.setItem(
        PURCHASING_REPORT_END_DATE_STORAGE_NAME,
        moment(this.filters.end_date)
      );
      let self = this;
      this.page = 1;
      this.filters.page = 1;
      this.infiniteId++;
      this.onClickButton = true;
      this.onHitBottom();
    },
    onHitBottom($state = null) {
      if (this.loading) {
        return;
      }
      let self = this;
      let filters = _.cloneDeep(this.filters);
      this.$set(
        filters,
        "start_date",
        moment(this.filters.start_date).format(LARAVEL_DATE_FORMAT)
      );
      this.$set(
        filters,
        "end_date",
        moment(this.filters.end_date).format(LARAVEL_DATE_FORMAT)
      );

      if (filters.fabric_type) {
        let findItem = _.find(self.fabricModels, { id: +filters.fabric_type });
        filters.fabric_type = findItem.class_name;
      }

      filters.company_id = this.companyId;
      this.$set(filters, "order", this.order);
      this.$set(filters, "sort", this.sort);

      self.$store
        .dispatch(HANDLE_INFINITE_SCROLL, {
          url: self.reportListUrl,
          filters: filters,
          $state: $state,
        })
        .then((result) => {
          this.onClickButton = false;

          this.infiniteId++;

          if (result.status) {
            if ($state) {
              $state.loaded();
            }
          } else {
            if ($state) {
              $state.complete();
            }
          }
        });
    },
    resetFilters() {
      (this.sort = "desc"),
        (this.order = "id"),
        this.setFilter({
          page: 1,
          per_page: 25,
          search: "",
          start_date: moment().subtract(30, "d").format(LARAVEL_DATE_FORMAT),
          end_date: moment().add(1, "d").format(LARAVEL_DATE_FORMAT),
        });
    },
    setup() {
      let self = this,
        promises = [];
      promises.push(
        this.$store.dispatch(GET_ITEMS, {
          url: "api/companies",
        })
      );

      promises.push(
        this.$store.dispatch(GET_ITEMS, {
          url: "api/supplier-companies",
        })
      );
      promises.push(
        this.$store.dispatch(GET_ITEMS, {
          url: "api/all-fabric-models",
          returnType: "stateless",
        })
      );

      promises.push(
        this.$store.dispatch(GET_ITEMS, {
          url: "api/expenses",
          returnType: "stateless",
        })
      );

      Promise.all(promises).then((results) => {
        if (results[0].status) {
          self.customerOptions = self.convertArrayToObjectByKey(
            results[0].data,
            "id",
            "name"
          );
        }

        if (results[1].status) {
          self.supplierCompanyOptions = self.convertArrayToObjectByKey(
            results[1].data,
            "id",
            "name"
          );
        }

        if (results[2].status) {
          self.fabricModels = results[2].data;
        }

        if (results[3].status) {
          self.expenses = results[3].data;
        }
      });
    },
  },
  created() {
    this.$store.commit(SET_LOADING, false);
    this.$store.commit(SET_ITEMS, {});
  },
  mounted() {
    let self = this;
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.$t("general.dashboard"), route: "dashboard" },
      { title: this.$t("menu.purchasing_invoice_report") },
    ]);

    if (!this.isUserGranted("EscTable", ["viewAny"])) {
      return false;
    }

    if (!this.filter) {
      this.resetFilters();
    }

    this.filters.end_date = localStorage.hasOwnProperty(
      PURCHASING_REPORT_END_DATE_STORAGE_NAME
    )
      ? moment(localStorage.getItem(PURCHASING_REPORT_END_DATE_STORAGE_NAME))
      : moment().add(1, "d").format(LARAVEL_DATE_FORMAT);

    this.filters.start_date = localStorage.hasOwnProperty(
      PURCHASING_REPORT_START_DATE_STORAGE_NAME
    )
      ? moment(localStorage.getItem(PURCHASING_REPORT_START_DATE_STORAGE_NAME))
      : moment().subtract(30, "d").format(LARAVEL_DATE_FORMAT);

    this.setup();
  },
  watch: {
    sort() {
      this.filterResult();
    },
    order() {
      this.filterResult();
    },
  },
};
</script>

<style>
*:focus {
  outline: none;
}

#dashboard-card-body {
  padding-bottom: 5px !important;
}
</style>
