var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mb-1 p-0 mx-0"},[_c('ul',{staticClass:"dashboard-tabs nav nav-pills nav-primary row row-paddingless m-0 p-0 flex-column flex-sm-row",attrs:{"role":"tablist"}},_vm._l((_vm.data),function(row,index){return (Number(row.parent_id) === 0)?_c('li',{staticClass:"nav-item d-flex col-sm flex-grow-1 flex-shrink-0 mb-3 mb-lg-0",on:{"click":function($event){_vm.modelProxy = row.id}}},[_c('a',{staticClass:"nav-link border py-10 d-flex flex-grow-1 rounded flex-column align-items-center justify-content-center items-center text-navy-blue",class:{active: _vm.isActive(row.id)},attrs:{"data-toggle":"pill"}},[_c('span',{staticClass:"nav-icon py-2 w-auto d-flex align-items-center justify-content-center items-center"},[(row.id === 7)?_c('span',{domProps:{"innerHTML":_vm._s(_vm.getIconByKey('icons.waybill.transfer', {
                class: 'w-35px h-35px d-inline-block object-fill cursor-pointer',
                style: _vm.isActive(row.id) ? '' : 'opacity: 0.5;'
              }))}}):_vm._e(),(row.id === 2)?_c('span',{domProps:{"innerHTML":_vm._s(_vm.getIconByKey('icons.waybill.accessory', {
                class: 'w-35px h-35px d-inline-block object-fill cursor-pointer',
                style: _vm.isActive(row.id) ? '' : 'opacity: 0.5;'
              }))}}):_vm._e(),(row.id === 1)?_c('span',{domProps:{"innerHTML":_vm._s(_vm.getIconByKey('icons.waybill.fabric', {
                class: 'w-35px h-35px d-inline-block object-fill cursor-pointer',
                style: _vm.isActive(row.id) ? '' : 'opacity: 0.5;'
              }))}}):_vm._e(),_c('span',{staticClass:"nav-text font-size-lg py-2 font-weight-boldest text-center d-inline-block pl-2 text-xl",attrs:{"id":_vm.isActive(row.id) ? 'active-navy' : ''}},[_vm._v(_vm._s(row.translations[0].name.toUpperCase()))])])])]):_vm._e()}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }