<template>
  <div class="mb-5 p-0 mx-0">
    <!--begin::Nav Tabs-->
    <ul class="dashboard-tabs nav nav-pills nav-primary row row-paddingless m-0 p-0 flex-column flex-sm-row"
        role="tablist">
      <li v-for="(value, index) in data" v-if="Number(value.parent_id) === Number(parentId)" @click="modelProxy = value.id" class="nav-item d-flex col-sm flex-grow-1 flex-shrink-0 mb-3 mb-lg-0">
        <a class="nav-link border py-10 d-flex flex-grow-1 rounded flex-column align-items-center justify-content-center items-center"
           :class="{active: isActive(value.id)}"
           data-toggle="pill">
          <span class="nav-icon py-2 w-auto d-flex align-items-center justify-content-center items-center">
          <span
              class="nav-text font-size-lg py-2 font-weight-bolder text-center d-inline-block pl-5 text-xl">{{
              $t(value.translations[0].name).toUpperCase()
            }}</span></span>
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "SecondWaybillNav",
  props: {
    model: {
      default: 1,
    },
    data: {
      default: [],
    },
    parentId: {
      required: false,
    }
  },
  computed: {
    modelProxy: {
      get(){
        return this.model;
      },
      set(value) {
        this.$emit('update:model', value)
      }
    }
  },
  methods: {
    isActive(value) {
      return Number(this.model) === Number(value);
    },
  },
  watch: {
    parentId(value) {
      let item = this.data.find(item => item.parent_id == value)
      this.modelProxy = item.id;
    }
  }
}
</script>

<style scoped>
.svg-icon.svg-icon-2x svg {
  width: 2.5rem !important;
  height: 2.5rem !important;
}

.nav-link {
  padding: 0.5rem 0.5rem !important;
}

.dashboard-tabs li {
  cursor: pointer;
}

.text-xl {
  font-size: 1.1rem !important;
}
</style>


<style lang="scss" scoped>
@import '@/assets/sass/components/_variables.demo.scss';

.svg-icon.svg-icon-2x svg {
  width: 2.5rem !important;
  height: 2.5rem !important;
}

.nav-link {
  padding: 0.5rem 0.5rem !important;
}

.dashboard-tabs li {
  cursor: pointer;
}

.text-xl {
  font-size: 1.3rem !important;
}

.nav.nav-pills.nav-primary .show > .nav-link .nav-icon .svg-icon svg g [fill], .nav.nav-pills.nav-primary .nav-link.active .nav-icon .svg-icon svg g [fill] {
  fill: $primary !important;
}

.nav.nav-pills.nav-primary .nav-link.active {
  color: $primary !important;
  background-color: transparent !important;
}
.nav.nav-pills.nav-primary .nav-link.active .nav-text {
  color: $primary !important;
}
.dashboard-tabs {
  .nav-item {
    margin-right: 0;
    .border {
      border-radius: 0 !important;
      border: none !important;
      border-top: 2px solid #4b4e5f !important;
      border-right: 2px solid #4b4e5f !important;
      border-bottom: 2px solid #4b4e5f !important;
    }
    &:last-child .border {
      border-right: none !important;
    }
  }
}

</style>
