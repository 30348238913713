<template>
    <div class="context-menu" v-show="show" :style="style" ref="context" tabindex="0" @blur="close">
        <slot></slot>
    </div>
</template>
<script>
import Vue from 'vue';

export default {
    name: 'CmpContextMenu',
    props: {
        display: Boolean,
    },
    data() {
        return {
            left: 0,
            top: 0,
            show: false,
        };
    },
    computed: {
        style() {
            return {
                top: this.top + 'px',
                left: this.left + 'px',
            };
        },
    },
    methods: {
        close() {
            this.show = false;
            this.left = 0;
            this.top = 0;
        },
        open(evt) {
            this.left = evt.pageX || evt.clientX;
            this.top = evt.pageY || evt.clientY;
            Vue.nextTick(() => this.$el.focus());
            this.show = true;
        },
    },
};
</script>
<style>
.context-menu {
    position: absolute;
    background: white;
    z-index: 999;
    outline: none;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    cursor: pointer;
    border-radius: 10px;
}

.context-menu ul {
    list-style: none;
    border-radius: 10px;
    border-radius: 10px;
    height: 15px;
}

.context-menu ul li {
    border-bottom: 1px solid #E0E0E0;
    margin: 0;
    padding: 5px 35px;
}

.context-menu ul li:hover {
    background: #1E88E5;
    color: #FAFAFA;
    border-radius: 10px;
}

.context-menu ul li:last-child {
    border-bottom: none;
}
</style>